import client from "../../../plugins/elastic";
import axios from "@/libs/axios";
import _ from 'lodash'
import useJwt from "@/auth/jwt/useJwt";
import {EventBus} from "@/plugins/event-bus";

const User = {
    namespaced: true,
    state: {
        data: {}
    },
    mutations: {
        SET_DATA(state, data) {
            state.data = data;
        },
        SET_FAVORITES(state, data) {
            let lists = [];
            for (let i = 0; i < state.data.lists.length; i++) {
                let list = state.data.lists[i];
                let products = []
                for (let j = 0; j < list.products.length; j++) {
                    let item = _.find(data, {id: state.data.lists[i].products[j]});
                    if (item) {
                        products.push(item);
                    }
                }
                list.products = products;
                lists.push(list);
            }
            state.data.lists = lists;
        },
        ADD_LIST(state, item) {
            state.data.lists.push(item);
        },
        REMOVE_LIST(state, id) {
            let index = _.findIndex(state.data.lists, {id: id});
            state.data.lists.splice(index, 1);
        },
        ADD_PRODUCT(state, data) {
            let index = _.findIndex(state.data.lists, {id: data.list.id});
            if (!_.isArray(state.data.lists[index].products)) {
                state.data.lists[index].products = []
            }
            state.data.lists[index].products.push(data.product);
        },
        REMOVE_PRODUCT(state, productId) {
            _.forEach(state.data.lists, (list, listIndex) => {
                let index = _.findIndex(list.products, {id: productId});
                if (index > -1) {
                    state.data.lists[listIndex].products.splice(index, 1);
                }
            })
        },
        SET_TOS_REMINDER(state, date) {
            state.data.tos_reminder = date
        },
        SHOULD_REVIEW_TOS(state, tosState) {
            state.data.should_review_tos = tosState
        }
    },
    actions: {
        fetch({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('user').then(response => {
                    commit('SET_DATA', response.data);
                    window.progressier.add({
                        id: response.data.id,
                        name: response.data.name
                    })
                    this.dispatch('user/fetchFavorites');
                    localStorage.setItem('userData', JSON.stringify(response.data))
                    resolve(response)
                }).catch(error => {
                    if (error.response.status === 403) {
                        this.dispatch('user/logout')
                    }
                    reject(error)
                });
            })
        },
        update({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.post('/user', data).then(response => {
                    commit('SET_DATA', response.data);
                    this.dispatch('user/fetchFavorites');
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            })
        },
        updatePassword(commit, data) {
            return new Promise((resolve, reject) => {
                axios.post('/user/update-password', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            })
        },
        fetchFavorites({commit}) {
            return new Promise((resolve, reject) => {
                let productsIds = [];
                _.each(this.state.user.data.lists, list => {
                    _.each(list.products, productId => {
                        productsIds.push(productId);
                    })
                })
                if (!productsIds.length) {
                    return;
                }
                client.search({
                    index: 'products',
                    type: 'products',
                    body: {
                        size: 1000,
                        query: {
                            ids: {
                                values: productsIds
                            }
                        }
                    }
                }).then(result => {
                    let favorites = [];
                    for (let i = 0; i < result.hits.hits.length; i++) {
                        favorites.push(result.hits.hits[i]._source);
                    }
                    commit('SET_FAVORITES', favorites)
                    resolve(result)
                }).catch(error => {
                    reject(error);
                });
            });
        },
        //@deprecated
        addList({commit}, item) {
            commit('ADD_LIST', item);
        },
        createList({commit}, item) {
            return new Promise((resolve, reject) => {
                axios.post('/user/lists', item).then(response => {
                    commit('ADD_LIST', response.data);
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        removeList({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/user/lists/' + id).then(response => {
                    commit('REMOVE_LIST', id);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        addProduct({commit}, data) {
            if (!data.product) {
                return Promise.reject('Missing product');
            }
            if (!data.list) {
                return Promise.reject('Missing list');
            }

            return new Promise((resolve, reject) => {
                axios.post('/user/lists/' + data.list.id + '/' + data.product.id).then(response => {
                    commit('ADD_PRODUCT', data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        removeProduct({commit}, productId) {
            if (!productId) {
                return Promise.reject('Missing product ID');
            }

            return new Promise(resolve => {
                _.forEach(this.state.user.data.lists, list => {
                    if (_.findIndex(list.products, {id: productId}) > -1) {
                        axios.delete('/user/lists/' + list.id + '/' + productId).then(() => {
                            commit('REMOVE_PRODUCT', productId);
                        })
                    }
                })
                resolve()
            })
        },
        deleteProfile() {
            return new Promise(((resolve, reject) => {
                axios.delete('/user').then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            }))
        },
        logout() {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')

            this.$http.post('/logout').then(() => {
                EventBus.$emit('logout')
            });

            // Redirect to login page
            this.$router.push({name: 'auth-login'})
        },
        setTosReminder({commit}, date) {
            commit('SET_TOS_REMINDER', date)
        },
        setShouldReviewTos({commit}, shouldReviewTos) {
            commit('SHOULD_REVIEW_TOS', shouldReviewTos)
        }
    }
}
export default User;
