import axios from "@/libs/axios";

const Products = {
    namespaced: true,
    state: {
        items: [],
        product: {}
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.items = data;
        },
        SET_PRODUCT(state, data) {
            state.product = data
        },
        ADD_REVIEW(state, review) {
            state.product.reviews.push(review)
        },
        SET_PRODUCT_CHART(state, data) {
            state.product.chart = data
        },
    },
    actions: {
        loadProduct({commit}, data) {
            let product_id = data.product_id
            let history_period = data.period
            return new Promise((resolve, reject) => {
                axios.get('/p/' + product_id, {
                    params: {
                        period: history_period
                    }
                }).then(response => {
                    commit('SET_PRODUCT', response.data)
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        addReview({commit}, review) {
            commit('ADD_REVIEW', review)
        },
        loadChart({commit}, data){
            let product_id = data.product_id
            let history_period = data.period
            return new Promise((resolve, reject) => {
                axios.get('/p/' + product_id + '/chart', {
                    params: {
                        period: history_period
                    }
                }).then(response => {
                    commit('SET_PRODUCT_CHART', response.data)
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}

export default Products;
