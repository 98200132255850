import _ from 'lodash'

const Compare = {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.items = data;
        },
        ADD_ITEM(state, item) {
            state.items.push(item);
            window.localStorage.setItem('compare', JSON.stringify(state.items));
        },
        REMOVE_ITEM(state, itemId) {
            state.items = _.filter(state.items, stateItem => {
                return stateItem.id !== itemId
            })
            window.localStorage.setItem('compare', JSON.stringify(state.items));
        }
    },
    actions: {
        fetch({commit}) {
            return new Promise(resolve => {
                let items = JSON.parse(window.localStorage.getItem('compare') || '[]');
                commit('SET_ITEMS', items);
                resolve(items);
            })
        },
        add({commit}, item) {
            commit('ADD_ITEM', item);

        },
        remove({commit}, itemId) {
            commit('REMOVE_ITEM', itemId);
        }
    }
}
export default Compare;
