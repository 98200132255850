import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import _ from 'lodash'
import VueMeta from 'vue-meta'

import VueWaypoint from 'vue-waypoint'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/notification'
import './plugins/ads'
import moment from 'moment'
// Axios Mock Adapter

import {mapActions} from "vuex";

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueWaypoint)
Vue.use(VueMeta)
Object.defineProperty(Vue.prototype, '_', {value: _});
Vue.prototype.moment = moment

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import {EventBus} from "@/plugins/event-bus";
import Image from "@/mixins/Image";
import Slugify from "@/mixins/Slugify";
import VueAppleLogin from 'vue-apple-login';

Vue.use(VueAppleLogin, {
    clientId: 'app.pricex',
    scope: 'name email',
    redirectURI: 'https://pricex.app/bg/auth/social/apple',
    state: moment().toString(),
    usePopup: true,
});
new Vue({
    mixins: {Image, Slugify},
    router,
    store,
    i18n,
    render: h => h(App),
    methods: {
        ...mapActions('categories', ['fetchCategories']),
        ...mapActions('attributes', ['fetchAttributes'])
    },
    mounted() {
        this.fetchCategories().then(() => {
            EventBus.$emit('globalCategoriesLoaded')
        })
        this.fetchAttributes().then(() => {
            EventBus.$emit('globalAttributesLoaded')
        })
        EventBus.$on('logout', () => {
            localStorage.removeItem('userData')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
        })
        this.$store.dispatch('ads/fetchAds')
    }
}).$mount('#app')
