import Vue from "vue";
const Image = Vue.mixin({
    methods: {
        toBase64(file){
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            })
        }
    }
});

export default Image;
