import axios from "@/libs/axios";
const Attributes = {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
      SET_ITEMS(state, data){
          state.items = data;
      }
    },
    actions: {
        fetchAttributes({commit}){
            return new Promise((resolve, reject) => {
                axios.get('/p/attributes').then(response => {
                    commit('SET_ITEMS', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        }
    }
}

export default Attributes;
