import axios from "@/libs/axios";

const Products = {
    namespaced: true,
    state: {
        items: [],
        product: {}
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.items = data;
        },
        ADD_ITEM(state, item) {
            state.items.push(item);
        }
    },
    actions: {
        storeReview(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/p/' + data.product_id + '/reviews', data).then(response => {
                    ctx.commit('ADD_ITEM', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default Products;