import axiosIns from "@/libs/axios";

const Blog = {
    namespaced: true,
    state: {
        postsPaginated: {},
        post: {}
    },
    mutations: {
        SET_POSTS(state, data) {
            state.postsPaginated = data;
        },
        SET_POST(state, data) {
            state.post = data;
        }
    },
    actions: {
        fetchPosts({commit}, data) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/blog', {
                    params: {
                        perPage: data.perPage || 10,
                        page: data.page || 1,
                        exclude: data.exclude || []
                    }
                }).then(response => {
                    commit('SET_POSTS', response.data);
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        getPost({commit}, data) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/blog/' + data.category_slug + '/' + data.post_slug).then(response => {
                    commit('SET_POST', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default Blog;
