export default [
    {
        path: '/terms/:page',
        name: 'terms',
        component: () => import('@/views/pages/terms/terms'),
        meta: {
            resource: 'Guest',
            action: 'read',
        }
    }
]