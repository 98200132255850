export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      resource: 'Guest',
      action: 'read',
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/pages/Contacts'),
    meta: {
      resource: 'Guest',
      action: 'read',
    },
  }
]
