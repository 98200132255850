import client from "../../../plugins/elastic";
import _ from 'lodash'

const Search = {
    namespaced: true,
    state: {
        items: [],
        categories: [],
        attributes: [],
        countries: [],
        min_price: 0,
        max_price: 1000000,
        filters: {
            categories: [],
            attributes: {},
            min_price: 0,
            max_price: 0
        },
        scroll_id: null
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.items = data;
        },
        ADD_ITEM(state, item) {
            state.items.push(item);
        },
        SET_CATEGORIES(state, data) {
            state.categories = data;
        },
        SET_ATTRIBUTES(state, data) {
            state.attributes = data;
        },
        SET_COUNTRIES(state, data) {
            state.countries = data;
        },
        SET_MIN_PRICE(state, data) {
            state.min_price = data;
        },
        SET_MAX_PRICE(state, data) {
            state.max_price = data;
        },
        SET_FILTERS(state, data) {
            state.filters = data;
        },
        SET_FILTER_CATEGORIES(state, data){
            state.filters.categories = data
        },
        ADD_CATEGORY_FILTER(state, categoryName) {
            if (state.filters.categories.indexOf(categoryName) === -1) {
                state.filters.categories.push(categoryName);
            }
        },
        SET_SCROLL_ID(state, id) {
            state.scroll_id = id
        }
    },
    actions: {
        search({commit}, searchBody) {
            let max_price = this.state.search.filters.max_price;

            searchBody.setPriceRange(0, max_price || 1000000)

            return new Promise((resolve, reject) => {
                client.search({
                    index: 'products',
                    type: 'products',
                    // scroll: '3m',
                    body: _.merge(searchBody.getBody(), searchBody.getAggregationQuery(searchBody.getBody().size))
                }).then(result => {
                    if (searchBody.getBody().from === 0) {
                        commit('SET_ITEMS', [])
                    }
                    for (let i = 0; i < result.hits.hits.length; i++) {
                        commit('ADD_ITEM', result.hits.hits[i]._source)
                    }
                    if (searchBody.getBody().from === 0) {
                        commit('SET_ATTRIBUTES', result.aggregations.attributes.items.buckets);
                        commit('SET_CATEGORIES', result.aggregations.categories.buckets);
                        commit('SET_COUNTRIES', result.aggregations.countries.buckets);
                        commit('SET_MAX_PRICE', result.aggregations.max_price.value);
                        commit('SET_MIN_PRICE', 0);
                    }
                    // commit('SET_SCROLL_ID', result._scroll_id)
                    resolve(result)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        scroll({commit}, scroll_id) {
            return new Promise((resolve, reject) => {
                client.scroll({
                    scrollId: scroll_id,
                    scroll: '3m'
                }).then(result => {
                    for (let i = 0; i < result.hits.hits.length; i++) {
                        commit('ADD_ITEM', result.hits.hits[i]._source)
                    }
                    resolve(result)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setFilters({commit}, data) {
            return new Promise(resolve => {
                let attributes = [];
                _.forEach(data.attributes, (value, key) => {
                    _.forEach(value, attributeValue => {
                        attributes.push({name: key, value: attributeValue});
                    })
                })
                data.attributes = attributes;
                commit('SET_FILTERS', data);
                resolve()
            })
        },
        getSimilar(commit, data) {
            return new Promise(resolve => {
                    client.search({
                        index: 'products',
                        type: 'products',
                        body: {
                            from: data.from || 0,
                            size: data.size,
                            query: {
                                more_like_this: {
                                    fields: ['name', 'categories'],
                                    like: [
                                        {'_index': 'products', '_id': data.product_id}
                                    ],
                                    min_term_freq: 1,
                                    max_query_terms: 6
                                }
                            }
                        }
                    }).then(result => {
                        resolve(result);
                    })
                }
            );
        },
        setCategories({commit}, categories){
          commit('SET_FILTER_CATEGORIES', categories)
        }
    }
}

export default Search;
