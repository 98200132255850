export default [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('@/views/pricex/authorization/Login'),
        meta: {
            resource: 'Guest',
            action: 'read',
        }
    },
    {
        path: '/auth/register',
        name: 'auth.register',
        component: () => import('@/views/pricex/authorization/Register'),
        meta: {
            resource: 'Guest',
            action: 'read',
        }
    },
    {
        path: '/auth/forgot-password',
        name: 'auth.forgot.password',
        component: () => import('@/views/pricex/authorization/ForgotPassword'),
        meta: {
            resource: 'Guest',
            action: 'read',
        }
    },
    {
        path: '/user/password-reset',
        name: 'auth.reset.password',
        component: () => import('@/views/pricex/authorization/ResetPassword'),
        meta: {
            resource: 'Guest',
            action: 'read'
        }
    },
    {
        path: '/auth/social/:driver',
        name: 'auth.social',
        component: () => import('@/views/pricex/authorization/SocialLogin'),
        meta: {
            resource: 'Guest',
            action: 'read'
        }
    }
]
