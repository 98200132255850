import axios from "@/libs/axios";

const ProductNotifications = {
    namespaced: true,
    state: {
        items: []
    },
    actions: {
        storeNotification(commit, data) {
            return new Promise((resolve, reject) => {
                axios.post('/user/notifications/' + data.product_id, {
                    price: data.expected_price
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default ProductNotifications;
