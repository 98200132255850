export default [
    {
        path: '/user/remove-account',
        name: 'user.account.remove',
        component: () => import('@/views/pricex/user/AccountRemoval'),
        meta: {
            resource: 'Guest',
            action: 'read'
        }
    },
    {
        path: '/user/:section',
        name: 'user.account',
        component: () => import('@/views/pricex/user/UserAccount'),
        meta: {
            resource: 'User',
            action: 'manage',
        }
    }
]