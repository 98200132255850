import Vue from 'vue'
import VueRouter from 'vue-router'
import {EventBus} from "@/plugins/event-bus";
import Meta from 'vue-meta'
// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import pages from './routes/pages'
import search from './routes/search'
import pricex from './routes/pricex'
import auth from './routes/auth'
import user from './routes/user'
import terms from './routes/terms'
import blog from './routes/blog'
import store from '@/store/index'

Vue.use(VueRouter)
Vue.use(Meta)
/* eslint-disable no-useless-escape */
let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');

if (['en', 'bg', 'el', 'ro'].indexOf(locale) < 0) {
    locale = 'bg'
}
const router = new VueRouter({
    mode: 'history',
    base: (locale.trim().length && locale !== "/") ? '/' + locale : 'bg',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            component: () => import('@/views/Landing'),
            name: '/',
            meta: {
                resource: 'Guest',
                action: 'read',
            }
        },
        ...pages,
        ...auth,
        ...search,
        ...user,
        ...terms,
        ...blog,
        ...pricex,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({name: 'auth.login'})

        // If logged in => not authorized
        return next({name: 'misc-not-authorized'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }

    if (store.state.user.data.should_review_tos) {
        if (store.state.user.data.tos_reminder) {
            let date = Date.parse(store.state.user.data.tos_reminder)
            if ((new Date()).getTime() > date) {
                EventBus.$emit('showTos')
            }
        } else {
            EventBus.$emit('showTos')
        }
    }
})

export default router
