import axiosIns from "@/libs/axios";

const Ads = {
    namespaced: true,
    state: {
        ads: {}
    },
    mutations: {
        SET_ADS(state, data) {
            state.ads = data
        }
    },
    actions: {
        fetchAds({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/campaigns')
                    .then(response => {
                        commit('SET_ADS', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}

export default Ads