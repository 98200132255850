<template>
  <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view/>
    </component>

    <scroll-to-top v-if="enableScrollToTop"/>
    <tos-component v-model="tosModal" @hidden="tosModal = false"></tos-component>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {useWindowSize, useCssVar} from '@vueuse/core'

import store from '@/store'
import useJwt from "@/auth/jwt/useJwt";

const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {EventBus} from "@/plugins/event-bus";
import TosComponent from "@/views/pricex/elements/TosComponent";
import i18n from '@/libs/i18n'

let ogLocale = function () {
  switch (i18n.locale) {
    case 'en':
      return 'en_gb'
    case 'el':
      return 'el_gr'
    case 'ro':
      return 'ro_ro'
  }
  return 'bg_bg'
}
export default {
  components: {
    TosComponent,

    // Layouts
    LayoutHorizontal,

    ScrollToTop,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      tosModal: false
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: i18n.t('page_titles.pricex_shop_wisely'),
    // all titles will be injected into this template
    titleTemplate: '%s | PriceX',
    htmlAttrs: {
      lang: i18n.locale
    },
    meta: [
      {
        name: 'description',
        content: i18n.t('descriptions.main')
      },
      {vmid: 'keywords', property: 'keywords', content: 'pricex, price comparison, products search, ecommerce, сравнение на цени, търсене на продукти'},
      {vmid: 'og:title', property: 'og:title', content: i18n.t('page_titles.pricex_shop_wisely')},
      {vmid: 'og:description', property: 'og:description', content: i18n.t('descriptions.main')},
      {property: 'og:site_name', content: 'PriceX'},
      {vmid: 'og:image',property: 'og:image', content: require('@/assets/images/meta/meta_img_' + i18n.locale + '.jpg')},
      {vmid: 'og:type', property: 'og:type', content: 'website'},
      {property: 'og:locale', content: ogLocale()},
      {vmid: 'robots', name: 'robots', content: 'index,follow'}
    ]
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }
  },
  setup() {
    const {skin, skinClasses} = useAppConfig()
    const {enableScrollToTop} = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {width: windowWidth} = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    if (localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)) {
      store.dispatch('user/fetch')
    } else {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
    }

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  created() {
    EventBus.$on('notification', function (data) {
      let variant = data.variant || 'info'
      this.$toast({
        component: ToastificationContent,
        props: {
          title: data.title,
          icon: data.icon || 'BellIcon',
          text: data.text,
          variant,
        },
      })
    })
    EventBus.$on('showTos', () => {
      this.tosModal = true
    })
  }
}
</script>
