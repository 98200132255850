import Vue from 'vue'
import {EventBus} from "@/plugins/event-bus";

const notify = function () {
    return {
        success(message, title) {
            EventBus.$emit('notification', {
                title: title || 'Success',
                text: message,
                variant: 'success',
                icon: 'CheckIcon'
            })
        },
        error(message, title) {
            EventBus.$emit('notification', {
                title: title || 'Error',
                text: message,
                variant: 'danger',
                icon: 'SlashIcon'
            })
        },
        warning(message, title) {
            EventBus.$emit('notification', {
                title: title || 'Warning',
                text: message,
                variant: 'warning',
                icon: 'AlertCircle'
            })
        },
        info(message, title) {
            EventBus.$emit('notification', {
                title: title || 'Info',
                text: message,
                variant: 'info',
                icon: 'InfoIcon'
            })
        }
    }


}

Vue.prototype.$notify = notify

export default notify