import AdManager from 'vue-google-ad-manager';
import Vue from 'vue'

let mappings = {
    banner:[
        { window :[0,0], sizes: [ [300,250], [300,100], [300,600] ] },
    ],
    rectangle:[
        { window: [0, 0], sizes: [ [300, 250] ] },
    ]
}

let sizes = {
    banner: [ [300,250], [300,100], [300,600] ],
    rectangle: [ [300, 250] ]
};

Vue.use(AdManager, {
    id: '1048529',
    mappings,
    sizes //optional
});