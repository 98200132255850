export default [
    {
        path: '/blog',
        name: 'blog',
        component: () => import('@/views/blog/BlogList'),
        meta: {
            resource: 'Guest',
            action: 'read',
        },
    },
    {
        path: '/blog/:category_slug/:slug',
        name: 'blog.view',
        component: () => import('@/views/blog/BlogDetail'),
        meta: {
            resource: 'Guest',
            action: 'read',
        },
    },
]
