import axios from "@/libs/axios";

const Subscriptions = {
    namespaced: true,
    state: {
        plans: {}
    },
    mutations: {
        SET_PLANS(state, data){
            state.plans = data;
        }
    },
    actions: {
        fetchPlans({commit}){
            axios.get(window.route('api.subscriptions.plans')).then(response => {
                commit('SET_PLANS', response.data.data);
            })
        }
    }
}

export default Subscriptions
