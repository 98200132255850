import axiosIns from "@/libs/axios";

const Automations = {
    namespaced: true,
    state: {
        items: [],
        events: [],
        actions: []
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.items = data;
        },
        SET_ACTIONS(state, data) {
            state.actions = data
        },
        SET_EVENTS(state, data) {
            state.events = data
        },
        ADD_AUTOMATION(state, data) {
            state.items.push(data)
        },
        REMOVE_AUTOMATION(state, automationId) {
            state.items.forEach(function (item, index) {
                if (item.id === automationId) {
                    state.items.splice(index, 1)
                }
            })
        }
    },
    actions: {
        fetchAutomations({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/user/automations').then(response => {
                    commit('SET_ITEMS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        fetchActions({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/automation/actions').then(response => {
                    commit('SET_ACTIONS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        fetchEvents({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/automation/events').then(response => {
                    commit('SET_EVENTS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeAutomation({commit}, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/user/automations', data).then(response => {
                    commit('ADD_AUTOMATION', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deleteAutomation({commit}, automationId) {
            return new Promise((resolve, reject) => {
                axiosIns.delete('/user/automations/' + automationId).then(response => {
                    commit('REMOVE_AUTOMATION', automationId)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
export default Automations;
