import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// pricex
import attributes from './pricex/Attributes'
import compare from './pricex/modules/Compare'
import search from './pricex/modules/Search'
import subscriptions from './pricex/modules/Subscriptions'
import user from './pricex/modules/User'
import products from './pricex/Products'
import reviews from './pricex/Reviews'
import product_notifications from './pricex/ProductNotifications'
import automations from './pricex/modules/Automations'
import website_requests from './pricex/user/WebsiteRequests'
import passport from './pricex/modules/Passport'
import categories from './pricex/Categories'
import blog from './pricex/Blog'
import ads from "./pricex/modules/Ads";

Vue.use(Vuex)

export default new Vuex.Store({ // eslint-disable-line
    modules: {
        app,
        appConfig,
        verticalMenu,
        //pricex
        attributes,
        compare,
        search,
        subscriptions,
        user,
        products,
        reviews,
        product_notifications,
        automations,
        website_requests,
        passport,
        categories,
        blog,
        ads
    },
    strict: process.env.DEV,
})
