import axiosIns from "@/libs/axios";

const Categories = {
    namespaced: true,
    state: {
        mapped: [],
        tree: []
    },
    mutations: {
        SET_MAPPED(state, data) {
            state.mapped = data;
        },
        SET_TREE(state, data) {
            state.tree = data;
        }
    },
    actions: {
        fetchCategories({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/p/categories').then(response => {
                    commit('SET_MAPPED', response.data.categories);
                    commit('SET_TREE', response.data.tree);
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        setMapped({commit}, data) {
            commit('SET_MAPPED', data);
        }
    }
}

export default Categories;
