import axiosIns from "@/libs/axios";

const WebsiteRequests = {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.items = data;
        },
        ADD_ITEM(state, data) {
            state.items.push(data)
        }
    },
    actions: {
        fetchWebsiteRequests({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/user/requests').then(response => {
                    commit('SET_ITEMS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeWebsiteRequest({commit}, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/user/requests', data).then(response => {
                    commit('ADD_ITEM', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default WebsiteRequests;
