<template>
  <b-modal
      v-model="visible"
      :title="$t('messages.please_confirm_changes_to_tos')"
      v-b-modal.modal-center
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      hide-header-close
      ok-only
      :ok-title="$t('buttons.remind_me_later')"
      @ok="setReminder"
      v-bind="$attrs"
      v-on="$listeners"
      width="30%">
    <p class="text-center" style="white-space: pre-wrap">
      {{ $t('messages.please_review_our_changes_to') }} <router-link :to="{name: 'terms', params: {page: 'terms'}}" target="_blank"
                                                           class="font-weight-bold text-primary">{{
        $t('messages.terms_and_conditions')
      }}</router-link>
      {{ $t('messages.and_confirm_your_agreement') }}
    </p>
    <b-form-checkbox v-model="tosAgree">
      {{ $t('messages.i_agree_with_terms_and_conditions') }}
    </b-form-checkbox>
  </b-modal>
</template>

<script>
import {BFormCheckbox, BModal} from "bootstrap-vue";
import {mapActions} from "vuex";

export default {
  name: "TosComponent",
  components: {
    BFormCheckbox, BModal
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tosAgree: false,
      visible: false
    }
  },
  methods: {
    ...mapActions('user', ['setTosReminder', 'setShouldReviewTos']),
    updateAgreement() {
      this.$http.post('/user/tos/update', {
        agree: this.tosAgree
      }).then(() => {
        this.$notify().success(this.$t('messages.agreement_saved'));
        this.setShouldReviewTos(false)
        this.$emit('input', false)
      }).catch(error => {
        this.$notify().error(error.message);
      })
    },
    setReminder() {
      let remindTime = new Date((new Date()).getTime() + 5 * 60000)
      this.setTosReminder(remindTime)
      this.$notify().info(this.$t('messages.we_will_remind_you_after', {minutes: 5}))
    }
  },
  watch: {
    tosAgree() {
      this.updateAgreement();
    },
    value(val) {
      this.visible = val
    }
  }
}
</script>
