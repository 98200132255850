export default [
    {
        path: '/search',
        name: 'search',
        component: () => import('@/views/pricex/SearchComponent'),
        meta: {
            resource: 'Guest',
            action: 'read',
        }
    }
]