import axiosIns from "@/libs/axios";


const Passport = {
    namespaced: true,
    state: {
        personalAccessTokens: [],
        scopes: []
    },
    mutations: {
        SET_PERSONAL_TOKENS(state, data) {
            state.personalAccessTokens = data;
        },
        SET_SCOPES(state, data) {
            state.scopes = data
        },
        DELETE_TOKEN(state, token_id) {
            state.personalAccessTokens.forEach((item, index) => {
                if (item.id === token_id) {
                    state.personalAccessTokens.splice(index, 1)
                }
            })
        },
        ADD_TOKEN(state, data) {
            state.personalAccessTokens.push(data)
        }
    },
    actions: {
        fetchPersonalAccessTokens({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns({
                    url: '/user/oauth/personal-access-tokens',
                }).then(response => {
                    commit('SET_PERSONAL_TOKENS', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        fetchScopes({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns({
                    url: '/user/oauth/scopes',
                }).then(response => {
                    commit('SET_SCOPES', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        revokeToken({commit}, tokenId) {
            return new Promise((resolve, reject) => {
                axiosIns.delete('/user/oauth/personal-access-tokens/' + tokenId).then(response => {
                    commit('DELETE_TOKEN', tokenId)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        storeToken({commit}, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/user/oauth/personal-access-tokens', data)
                    .then(response => {
                        commit('ADD_TOKEN', response.data.token)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}

export default Passport;
