import Vue from 'vue'
import i18n from '@/libs/i18n'
// axios
import axios from 'axios'
import {EventBus} from "@/plugins/event-bus";
// let locale = document.getElementsByTagName("html")[0].getAttribute('lang');
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_BACKEND_URL + i18n.locale + '/v1',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.response.use(response => {
  return response
}, error => {
  if(error.response.status === 401){
    EventBus.$emit('logout')
  }
  throw error
})
Vue.prototype.$http = axiosIns

export default axiosIns
