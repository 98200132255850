import axiosIns from "@/libs/axios";

export default [
    {
        path: '/prices',
        name: 'prices',
        component: () => import('@/views/pricex/Prices'),
        meta: {
            resource: 'Guest',
            action: 'read',
        },
    },
    {
        path: '/:category',
        name: 'category',
        component: () => import('@/views/pricex/Category'),
        meta: {
            resource: 'Guest',
            action: 'read',
        }
    },
    {
        path: '/:slug/:product',
        name: 'products.view',
        component: () => import('@/views/pricex/Product'),
        meta: {
            resource: 'Guest',
            action: 'read',
        },
        beforeEnter: (to, from, next) => {
            let productId = to.params.product
            axiosIns.options('/p/' + productId).then(() => {
                next()
            }).catch(() => {
                next({name: 'error-404'})
                console.clear()
            })
        }
    }
]
